// Fonts
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

// Font Awesome
@import "@fortawesome/fontawesome-free/css/all.css";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
